import abgoojung from "assets/dummy/abgoojung.jpg";
import gundae from "assets/dummy/gundae.jpg";
import bucheon from "assets/dummy/bucheon.jpg";
import gangnam from "assets/dummy/gangnam.jpg";
import magocnaru from "assets/dummy/magocnaru.jpg";
import cheongjoo from "assets/dummy/cheongjoo.jpg";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "views/web/components/slider";

const { kakao } = window;

const Store = () => {
  const elementRef = useRef(null);
  const [scrollActive, setScrollActive] = useState(false);
  const callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting && !scrollActive) return setScrollActive(true);

      const el = document.querySelector("#store .title");
      if (el.classList.contains("active")) return;
      el.classList.add("active");
    });
  };

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (!elementRef.current) return;

    const options = { root: null, threshold: 0.3 };
    const io = new IntersectionObserver(callback, options);
    io.observe(elementRef.current);
    return () => {
      io.disconnect();
    };
  }, []);

  useEffect(() => {
    // // 마커를 클릭하면 장소명을 표출할 인포윈도우 입니다
    var infowindow = new kakao.maps.InfoWindow({ zIndex: 1 });
    const mapContainer = document.getElementById("map"); //지도를 담을 영역의 DOM 레퍼런스
    const mapOption = {
      //지도를 생성할 때 필요한 기본 옵션
      center: new kakao.maps.LatLng(37.5259628, 127.0376468), //지도의 중심좌표.
      level: 2, //지도의 레벨(확대, 축소 정도)
    };
    const map = new kakao.maps.Map(mapContainer, mapOption); //지도 생성 및 객체 리턴
    // 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
    var mapTypeControl = new kakao.maps.MapTypeControl();
    // 지도에 컨트롤을 추가해야 지도위에 표시됩니다
    // kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
    map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);
    // 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다
    var zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
    // 마커를 표시할 위치와 내용을 가지고 있는 객체 배열입니다
    var positions = [
      {
        content:
          "<div class='marker_info_wrapper'><h4>도시맥주 압구정점</h4><hr/><h6>서울 강남구 선릉로 157길 23-4 ,1층</h6></div>",
        latlng: new kakao.maps.LatLng(37.5259628, 127.0376468),
      },
    ];
    for (var i = 0; i < positions.length; i++) {
      // 마커를 생성합니다
      var marker = new kakao.maps.Marker({
        map: map, // 마커를 표시할 지도
        position: positions[i].latlng, // 마커의 위치
      });
      // 마커에 표시할 인포윈도우를 생성합니다
      var infowindow = new kakao.maps.InfoWindow({
        content: positions[i].content, // 인포윈도우에 표시할 내용
      });
      // 마커에 mouseover 이벤트와 mouseout 이벤트를 등록합니다
      // 이벤트 리스너로는 클로저를 만들어 등록합니다
      // for문에서 클로저를 만들어 주지 않으면 마지막 마커에만 이벤트가 등록됩니다
      kakao.maps.event.addListener(
        marker,
        "click",
        makeOverListener(map, marker, infowindow)
      );
    }
    // 인포윈도우를 표시하는 클로저를 만드는 함수입니다
    function makeOverListener(map, marker, infowindow) {
      return function () {
        infowindow.open(map, marker);
      };
    }
  }, []);
  return (
    <>
      <section id="store" ref={elementRef}>
        <div className="store__wrapper container">
          <h1 className="title">매장안내</h1>
          <div id="map"></div>
        </div>
      </section>
      <section id="open">
        <div className="open__wrapper container">
          <div className="open_store__items">
            <Slider {...sliderSettings}>
              <div>
                <div className="open_store__item">
                  <img src={abgoojung}></img>
                  <div className="item__discription">
                    <h6>서울 강남구 선릉로 157길 23-4 ,1층</h6>
                  </div>
                </div>
                <h3 className="open-store__name">도시맥주 압구정점</h3>
              </div>
              <div>
                <div className="open_store__item">
                  <img src={gundae}></img>
                  <div className="item__discription">
                    <h6>서울 광진구 아차산로33길 44 ,1층</h6>
                  </div>
                </div>
                <h3 className="open-store__name">도시맥주 건대점</h3>
              </div>
              <div>
                <div className="open_store__item">
                  <img src={bucheon}></img>
                  <div className="item__discription">
                    <h6>경기 부천시 원미구 부일로459번길 20 ,1층 115호</h6>
                  </div>
                </div>
                <h3 className="open-store__name">도시맥주 부천역점</h3>
              </div>
              <div>
                <div className="open_store__item">
                  <img src={gangnam}></img>
                  <div className="item__discription">
                    <h6>봉은사로18길 90 1층</h6>
                  </div>
                </div>
                <h3 className="open-store__name">도시맥주 강남역점</h3>
              </div>
              <div>
                <div className="open_store__item">
                  <img src={cheongjoo}></img>
                  <div className="item__discription">
                    <h6>충북 청주시 청원구 율량로202번길 14 1층 102호</h6>
                  </div>
                </div>
                <h3 className="open-store__name">도시맥주 청주 율량점</h3>
              </div>
              <div>
                <div className="open_store__item">
                  <img src={magocnaru}></img>
                  <div className="item__discription">
                    <h6>서울 강서구 마곡서로 158 1층 108, 109호</h6>
                  </div>
                </div>
                <h3 className="open-store__name">도시맥주 마곡나루점</h3>
              </div>
              <div>
                <div className="open_store__item __empty">
                  <div>
                    <h1>COMMING SOON</h1>
                  </div>
                </div>
                <h3 className="open-store__name">도시맥주 청주 오송점</h3>
              </div>
              <div>
                <div className="open_store__item __empty">
                  <div>
                    <h1>COMMING SOON</h1>
                  </div>
                </div>
                <h3 className="open-store__name">도시맥주 성신여대점</h3>
              </div>
              <div className="open_store__item __empty">
                <div className="open-store__text-box">
                  <h1>WHO'S</h1>
                  <p className="open-store__strong">NEXT?</p>
                </div>
                {/* <div className="item__discription">
                <h3>도시맥주 압구정점(9월 오픈예정)</h3>
                <h6>서울 강남구 선릉로 157길 23-4 ,1층</h6>
              </div> */}
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Store;
